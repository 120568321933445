

import {
  defineComponent,
  ref,
    onMounted
} from 'vue'
import {ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { useRouter} from 'vue-router'
import { useStore } from '@/store'

import { register, registerAuthImage, registerSendSms } from '@/api/user'

import {setToken} from '@/utils/auth'
import {message} from 'ant-design-vue'
import {rules} from '@typescript-eslint/eslint-plugin'
interface FormInfo {
  loginName: string;
  userName:string;
  password:string;
  authKey:string;
  sms:string;
  confirmPassword:string
}
const phoneCheck = (rule:any, value:any, callback:any)=>{
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if( typeof (value) === 'undefined' || value === ''){
    return Promise.resolve()
  }else {
    if (!reg.test(value)) {
      // callback('请输入正确手机格式')
      return Promise.reject("请输入正确手机格式");
      // return
    }
  }
  return Promise.resolve()

}
export default defineComponent({
  name: 'Register',
  props: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const formRef = ref();
    let formInfo = ref<FormInfo>({
      loginName:'',
      userName:'',
      password:'',
      authKey:'',
      sms:'',
      confirmPassword:''
    })
    const rules = ref({
      loginName:[
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { validator:phoneCheck }
      ],
      userName:[
        { required: true, message: '请输入用户名', trigger: 'blur' },
      ],
      password:[
        { required: true, message: '请输入密码', trigger: 'blur' },
      ],
      authKey:[
        { required: true, message: '请输入验证码', trigger: 'blur' },
      ],
      confirmPassword:[
        { required: true, message: '请输入确认密码', trigger: 'blur' },
        { validator:(rules:any,value:string,callback:void)=>{
            if(value != formInfo.value.password){
              return Promise.reject(new Error('与密码不同'))
            }
            return Promise.resolve()
          }
        }
      ]
    })
    let phone = ref('')
    let  labelCol=ref({ span: 4 }),
        wrapperCol=ref( { span: 20 })
    let partLoading =ref(false)
    let authImage = ref('')
    let count = ref(60)
    let issend = ref(false)
    // let authKey:string = ''
    onMounted(()=>{
      getAuthImage()
    })

    //获取注册验证码信息
    const getAuthImage = ()=>{
      registerAuthImage({}).then((res)=>{
        // console.log(res)
        let data = res.data
        authImage.value = 'data:image/jpeg;base64,'+data.authImage
        // authKey = data.authKey

      })
    }
    //倒计时
    const countDown= ()=>{
      if (count.value === 1) {//当为0的时候，liked设置为true，button按钮显示内容为 获取验证码
        issend.value=false
      } else {
       count.value = count.value-1
        setTimeout(() => countDown(), 1000)//每一秒调用一次
      }
    }

    const getSendSms=()=>{
      if(!formInfo.value.loginName){
        message.warning('请输入手机号')
        return
      }
      if(!formInfo.value.authKey){
        message.warning('请输入验证码')
        return
      }
      let data = {
        "phone": formInfo.value.loginName,
        "authKey": formInfo.value.authKey,
      }
      registerSendSms(data).then((res)=>{
        // console.log(res)
        if (res.httpCode == 200){
          issend.value = true
          countDown()
        }
      })
    }


    //用户注册
    const handleSubmit = () =>{
      // formRef.value.validate().then(()=>{
      var user = {
        loginName: formInfo.value.loginName,
        userName: formInfo.value.userName,
        password: formInfo.value.password,
      }
        var data ={
          "user": JSON.stringify(user),
          "authKey": formInfo.value.authKey,
          "sms": formInfo.value.sms,
        }
        register(data).then((res)=>{
          // console.log(res)
          if(res.httpCode == 200){
            message.success('注册成功')
            router.push('/login')
          }
        })
      // }).catch((error: ValidateErrorEntity<FormInfo>) => {
      //   console.log('error', error);
      // });
    }

    // 点击重置
    const handleReset = () => {
      formInfo.value.loginName = '';
      formInfo.value.password = '';
    }
    return {
      formInfo,
      labelCol,
      wrapperCol,
      partLoading,
      formRef,
      rules,
      authImage,
      count,
      issend,
      phone,
      handleSubmit,
      handleReset,
      getAuthImage,
      getSendSms
    }

  },
})
