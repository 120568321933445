<template>
  <div class="register">
    <div class="register-component-layout">
      <Form
          ref="formRef"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :model="formInfo"
          @submit="handleSubmit"
          @keydown.enter="handleSubmit">
        <FormItem
            label="手机号"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="loginName"
        >
          <Input
              type="text" allow-clear
              v-model:value="formInfo.loginName"
          />

        </FormItem>
        <FormItem
            label="用户名"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="userName"
        >
          <Input
              type="text" allow-clear
              v-model:value="formInfo.userName"
          />

        </FormItem>
        <FormItem
            label="密码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="password"
        >
          <Input
              type="password" allow-clear
              v-model:value="formInfo.password"
          />
        </FormItem>
        <FormItem
            label="确认密码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="confirmPassword"
        >
          <Input
              type="password" allow-clear
              v-model:value="formInfo.confirmPassword"
          />
        </FormItem>
        <FormItem
            label="验证码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="authKey"
        >
          <Row :gutter="20">
            <Col :span="18">
              <Input
                  type="text" allow-clear
                  v-model:value="formInfo.authKey"
              />
            </Col>
            <Col :span="6">
              <img class="authImg" :src="authImage" @click="getAuthImage"/>
            </Col>

          </Row>
        </FormItem>
        <FormItem
            label="短信验证码"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            name="phone"
        >
          <Row :gutter="20">
            <Col :span="18">
              <Input
                  type="tel" allow-clear
                  v-model:value="formInfo.sms"
              />
            </Col>
            <Col :span="6">
              <Button type="primary" v-if="!issend" @click="getSendSms">获取验证码</Button>
              <Button type="primary" v-if="issend">{{count}}s</Button>
            </Col>

          </Row>
        </FormItem>
        <FormItem
            class="text-left"
            :wrapper-col="{ offset: 4, span: 20 }"
        >
          <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              style="border-radius: 5px;"
          >注册</Button>

          <!--          <Button-->
          <!--              type="default"-->
          <!--              @click="handleReset"-->
          <!--              style="margin-left: 20px;"-->
          <!--          >重置</Button>-->

        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script lang="ts">

import {
  defineComponent,
  ref,
    onMounted
} from 'vue'
import {ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { useRouter} from 'vue-router'
import { useStore } from '@/store'

import { register, registerAuthImage, registerSendSms } from '@/api/user'

import {setToken} from '@/utils/auth'
import {message} from 'ant-design-vue'
import {rules} from '@typescript-eslint/eslint-plugin'
interface FormInfo {
  loginName: string;
  userName:string;
  password:string;
  authKey:string;
  sms:string;
  confirmPassword:string
}
const phoneCheck = (rule:any, value:any, callback:any)=>{
  const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
  if( typeof (value) === 'undefined' || value === ''){
    return Promise.resolve()
  }else {
    if (!reg.test(value)) {
      // callback('请输入正确手机格式')
      return Promise.reject("请输入正确手机格式");
      // return
    }
  }
  return Promise.resolve()

}
export default defineComponent({
  name: 'Register',
  props: {},
  setup() {
    const store = useStore()
    const router = useRouter()
    const formRef = ref();
    let formInfo = ref<FormInfo>({
      loginName:'',
      userName:'',
      password:'',
      authKey:'',
      sms:'',
      confirmPassword:''
    })
    const rules = ref({
      loginName:[
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { validator:phoneCheck }
      ],
      userName:[
        { required: true, message: '请输入用户名', trigger: 'blur' },
      ],
      password:[
        { required: true, message: '请输入密码', trigger: 'blur' },
      ],
      authKey:[
        { required: true, message: '请输入验证码', trigger: 'blur' },
      ],
      confirmPassword:[
        { required: true, message: '请输入确认密码', trigger: 'blur' },
        { validator:(rules:any,value:string,callback:void)=>{
            if(value != formInfo.value.password){
              return Promise.reject(new Error('与密码不同'))
            }
            return Promise.resolve()
          }
        }
      ]
    })
    let phone = ref('')
    let  labelCol=ref({ span: 4 }),
        wrapperCol=ref( { span: 20 })
    let partLoading =ref(false)
    let authImage = ref('')
    let count = ref(60)
    let issend = ref(false)
    // let authKey:string = ''
    onMounted(()=>{
      getAuthImage()
    })

    //获取注册验证码信息
    const getAuthImage = ()=>{
      registerAuthImage({}).then((res)=>{
        // console.log(res)
        let data = res.data
        authImage.value = 'data:image/jpeg;base64,'+data.authImage
        // authKey = data.authKey

      })
    }
    //倒计时
    const countDown= ()=>{
      if (count.value === 1) {//当为0的时候，liked设置为true，button按钮显示内容为 获取验证码
        issend.value=false
      } else {
       count.value = count.value-1
        setTimeout(() => countDown(), 1000)//每一秒调用一次
      }
    }

    const getSendSms=()=>{
      if(!formInfo.value.loginName){
        message.warning('请输入手机号')
        return
      }
      if(!formInfo.value.authKey){
        message.warning('请输入验证码')
        return
      }
      let data = {
        "phone": formInfo.value.loginName,
        "authKey": formInfo.value.authKey,
      }
      registerSendSms(data).then((res)=>{
        // console.log(res)
        if (res.httpCode == 200){
          issend.value = true
          countDown()
        }
      })
    }


    //用户注册
    const handleSubmit = () =>{
      // formRef.value.validate().then(()=>{
      var user = {
        loginName: formInfo.value.loginName,
        userName: formInfo.value.userName,
        password: formInfo.value.password,
      }
        var data ={
          "user": JSON.stringify(user),
          "authKey": formInfo.value.authKey,
          "sms": formInfo.value.sms,
        }
        register(data).then((res)=>{
          // console.log(res)
          if(res.httpCode == 200){
            message.success('注册成功')
            router.push('/login')
          }
        })
      // }).catch((error: ValidateErrorEntity<FormInfo>) => {
      //   console.log('error', error);
      // });
    }

    // 点击重置
    const handleReset = () => {
      formInfo.value.loginName = '';
      formInfo.value.password = '';
    }
    return {
      formInfo,
      labelCol,
      wrapperCol,
      partLoading,
      formRef,
      rules,
      authImage,
      count,
      issend,
      phone,
      handleSubmit,
      handleReset,
      getAuthImage,
      getSendSms
    }

  },
})
</script>

<style lang="scss" scoped>
  .register {
    height: 100%;
    &-component-layout {
      background-color: #fff;
      padding: 20px;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, .1);
      border-radius: 5px;
      width: 500px;
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -80%);
      ::v-deep .ant-form-item-label > label::after {
        content: '';
      }
    }
    .authImg {
      width: 100%;
      vertical-align: middle;
    }
  }
</style>